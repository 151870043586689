@import "./variables";

@mixin tablet {
    @media (min-width: $tablet-breakpoint) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop-breakpoint) {
        @content;
    }
}