.win {
  border: 2px solid black;
  padding: 1rem 1.5rem 0.5rem;
  margin-bottom: 1rem;
  border-radius: 10px;

  &__subheading {
    padding: .5rem;
  }

  &__gif {
    max-width: 100%;
  }

  &__answer {
    padding: 1rem;
  }

  &__description {
    padding-bottom: 1rem;
  }
}