@use "../../styles/partials/mixins"as *;

.guess {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem auto;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

  @include tablet {
    padding: 1rem 0;
    flex-direction: row;
  }

  &__header{
    border-top: 2px solid rgb(155, 155, 155);
    padding-top: .75rem ;
    // border-bottom: 2px solid lightgray;
  }

  &__text {
    margin: 0;
    color: rgb(33, 186, 33);
    font-size: 1.3rem;
    
    &--wrong {
      color: red;
    }
    &--close {
      color: rgb(255, 206, 12);
    }
  }

  &__arrow {
    margin: 0;
    padding-left: 1.5rem;
    font-size: 2.5rem;
  }

  &__field-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    width: 100%;
    flex: 1.5;
    padding: .5rem 0;
    
    
    @include tablet {
      padding: 0;
    }

    &--middle {
      border-top: 2px solid lightgray;
      border-bottom: 2px solid lightgray;

      @include tablet {
        border: none;
        border-left: 2px solid lightgray;
        border-right: 2px solid lightgray;
      }
    }

    &--year {
      flex: 1;
    }
  }


}