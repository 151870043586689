@use "./styles/partials/mixins"as *;

.main {
  text-align: center;
  width: 80%;
  max-width: 1000px;
  margin: auto;

  &__form-wrapper {
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;

    @include tablet {
      flex-direction: row;

    }
  }

  &__image {
    width: 60%;
  }

  &__label {

    @include tablet {
      padding-right: 1rem;

    }
  }

  &__select {
    width: 100%;
    padding: 1rem .25rem;
    margin: .4rem;
    border-radius: 10px;
    text-align: center;

    @include tablet {
      width: 20%;
      margin: 0 .4rem;
    }
  }

  &__button {
    font-size: 1.8rem;
    padding: 2rem;
    border-radius: 10px;
    margin: 1rem 0 0;
    width: 100%;

    background-color: rgb(255, 81, 81);
    border-radius: 4px;
    border: 1px solid black;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Farfetch Basis", "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;

    max-width: none;
    margin-bottom: 1rem;
    min-height: 44px;
    min-width: 10px;
    outline: none;
    overflow: hidden;
    padding: 9px 20px 8px;
    text-align: center;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;

    &:hover {
      opacity: .75;
    }
  }

  &__how-to {
    padding-top: 2px;
    cursor: pointer;
    color: blue
  }

  &__title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: .3rem;
  }

  &__logo {
    height: 2.2rem;
  }
}