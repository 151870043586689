@use "../../styles/partials/mixins"as *;

.how-to {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  overflow-y: scroll;
  position: absolute;
  height: 60%;
  width: 80%;
  top: 10%;
  left: 10%;
  background-color: #FFF;
  border: 2px solid black;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  @include tablet {
    width: 40%;
    left: 30%;
  }

  &__title {
    padding-bottom: 1rem;
  }

  &__text {
    padding: .4rem 2rem;
  }

  &__button {
    font-size: 1.8rem;
    padding: 2rem;
    border-radius: 10px;
    margin: 1rem 0 2rem;
    width: 50%;
    background-color: rgb(255, 81, 81);
    border-radius: 4px;
    border: 1px solid black;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Farfetch Basis", "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;

    max-width: none;
    min-height: 44px;
    min-width: 10px;
    outline: none;
    overflow: hidden;
    padding: 9px 20px 8px;
    text-align: center;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;


    &:hover {
      opacity: .75;
    }

  }
}